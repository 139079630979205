var webPsupport = (function() {
  var webP = new Image();
  webP.onload = WebP.onerror = function () {
    callback(webP.height == 2);
  };
  webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
});

var $b = $('body');
webPsupport ? $b.addClass('webp') : $b.addClass('no-webp');

// Modal
var modalTrigger = $('.p-modal-trigger')
var modalOverlay = '<div class="modal-overlay"></div>'
var modal = '.p-modal'
var videoId
var playerId
var player

// This code loads the IFrame Player API code asynchronously.
var tag = document.createElement('script');
tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

function stopVideo() {
    player.stopVideo();
}

$("[modal-type='video']").each(function(){
    var id = $(this).attr('id')
    if ($(this).children().length < 1) {
        $(this).append('<div class="player-wrapper"><div id="player-' + id + '"></div></div>')
    }
});

$(modal).hide()
modalTrigger.click(function () {

    $('.modal-overlay').remove()

    var modalId = '#' + $(this).attr('modal-target')
    $(modalOverlay).appendTo('body').hide().fadeIn()
    if ($(modalId).attr('modal-width')){
        var modalWidth = $(modalId).attr('modal-width')
        $(modalId).css('width', modalWidth)
    }
    $(modalId).appendTo('body').fadeIn()
    $(modalId).append('<div class="p-modal-close"><i class="fa fa-close"></i></div>')



    // Type Video
    if ($(modalId).attr('modal-type') == 'video'){
        videoId = $(modalId).attr('modal-video-id')
        playerId = $(modalId).find('.player-wrapper').children().attr('id')

        // This function creates an <iframe> (and YouTube player) after the API code downloads.
        player = new YT.Player(playerId, {
            events: {
                'onReady': onPlayerReady,
                //'onStateChange': onPlayerStateChange
            },
            playerVars: {
                'autoplay': 1,
                'controls': 2,
                'rel' : 0,
                'fs' : 1,
                'loop': 1,
                'showinfo': 0
            }
        });
    }

})


    // The API will call this function when the video player is ready.
    function onPlayerReady(event) {
        event.target.cueVideoById({
            videoId: videoId
        })
        event.target.playVideo();
    }

    // The API calls this function when the player's state changes.
    //    The function indicates that when playing a video (state=1),
    //    the player should play for six seconds and then stop.
    var done = false;
    function onPlayerStateChange(event) {
        if (event.data == YT.PlayerState.PLAYING && !done) {
            setTimeout(stopVideo, 6000);
            done = true;
        }
    }



$("body").on("click", ".modal-overlay", function(e){
    $(modal).hide()
    $('.p-modal-close').remove()
    $('.modal-overlay').fadeOut().remove()
    stopVideo()
})

$("body").on("click", ".p-modal-close", function(e){
    $(this).parent('.p-modal').hide()
    $('.modal-overlay').fadeOut().remove()
    $('.p-modal-close').remove()
    stopVideo()
})




    $('.select').each(function(){
    if (!$(this).parents('.toCopy').length) {
        var $this = $(this), numberOfOptions = $(this).children('option').length;

        $this.addClass('select-hidden');
        $this.wrap('<div class="select"></div>');
        $this.after('<div class="select__styled"></div>');

        var $styledSelect = $this.next('div.select__styled');
        $styledSelect.text($this.children('option').eq(0).text());

        var $list = $('<ul />', {
            'class': 'select__options'
        }).insertAfter($styledSelect);

        for (var i = 0; i < numberOfOptions; i++) {
            $('<li />', {
                text: $this.children('option').eq(i).text(),
                rel: $this.children('option').eq(i).val()
            }).appendTo($list);
        }

        var $listItems = $list.children('li');

        $styledSelect.click(function (e) {
            e.stopPropagation();
            $('div.select__styled.active').not(this).each(function () {
                $(this).removeClass('active').next('ul.select__options').hide();
            });
            $(this).toggleClass('active').next('ul.select__options').toggle();
        });

        $listItems.click(function (e) {
            e.stopPropagation();
            $styledSelect.text($(this).text()).removeClass('active');
            $this.val($(this).attr('rel'));
            $list.hide();
        });

        $(document).click(function () {
            $styledSelect.removeClass('active');
            $list.hide();
        });
    }

});



// Replace all SVG images with inline SVG
$('img.svg-in').each(function(){
    var $img = $(this);
    var imgID = $img.attr('id');
    var imgClass = $img.attr('class');
    var imgURL = $img.attr('src');

    $.get(imgURL, function(data) {
        // Get the SVG tag, ignore the rest
        var $svg = $(data).find('svg');

        // Add replaced image's ID to the new SVG
        if(typeof imgID !== 'undefined') {
            $svg = $svg.attr('id', imgID);
        }
        // Add replaced image's classes to the new SVG
        if(typeof imgClass !== 'undefined') {
            $svg = $svg.attr('class', imgClass+' replaced-svg');
        }

        // Remove any invalid XML tags as per http://validator.w3.org
        $svg = $svg.removeAttr('xmlns:a');

        // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
        if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
            $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
        }

        if(!$svg.attr('viewBox')){
            $svg.attr('viewBox', ('0 0 '
                + $svg.attr('width').match(/[0-9]+\.[0-9]*/) + ' '
                + $svg.attr('height').match(/[0-9]+\.[0-9]*/)));
        }

        // Replace image with new SVG
        $img.replaceWith($svg);

    }, 'xml');
});